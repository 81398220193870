<div class="about-section">
  <div class="inner-container">
    <h1>About Us</h1>
    <p class="text">
      tievs.com is a website where you can buy and sell almost everything. The best deals are often done with people who
      live in your own city or on your own street, so on tievs.com it's easy to buy and sell locally. All you have to do
      is select your region. It takes less than 2 minutes to post an ad on tievs.com. You can sign up for a free account
      and post ads easily every time. tievs.com has the widest selection of popular second hand items all over Sri Lanka,
      which makes it easy to find exactly what you are looking for. So if you're looking for a car, mobile phone, house,
      computer or maybe a pet, you will find the best deal on tievs.com. Our website does not specialize in any specific
      category - here you can buy and sell items in more than 50 different categories. We also carefully review all ads
      that are being published, to make sure the quality is up to our standards.
    </p>
  </div>
</div>
