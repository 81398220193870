import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ItemsService } from '../items.service';

@Component({
  selector: 'app-ad-detail',
  templateUrl: './ad-detail.component.html',
  styleUrls: ['./ad-detail.component.sass'],
})
export class AdDetailComponent implements OnInit {
  ad;
  public recommend = [];
  public errorMsg;
  // tslint:disable-next-line:variable-name
  constructor(
    private route: ActivatedRoute,
    private _itemService: ItemsService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.getAd(params.id);
    });
    this.route.params.subscribe((params) => {
      this.getRecommends(params.id);
    });
  }

  getAd(adID): void {
    this._itemService.getItem(adID).subscribe(
      (data) => (this.ad = data),
      (error) => (this.errorMsg = error)
    );
  }

  getRecommends(adID): void {
    this._itemService.getRecommend(adID).subscribe(
      (data) => (this.recommend = data),
      (error) => (this.errorMsg = error)
    );
  }
}
