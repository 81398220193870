import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {HomeComponent} from './home/home.component';
import {AlladsComponent} from './allads/allads.component';
import {AboutComponent} from './about/about.component';
import {LoginComponent} from './login/login.component';
import {TermsComponent} from './terms/terms.component';
import {PrivacyComponent} from './privacy/privacy.component';
import {RegisterComponent} from './register/register.component';
import {NotfoundComponent} from './notfound/notfound.component';
import { PostAdComponent } from './post-ad/post-ad.component';
import { AdDetailComponent } from './ad-detail/ad-detail.component';

const routes: Routes = [
  {path: 'ads', component: AlladsComponent},
  {path: 'ad/:id', component: AdDetailComponent},
  {path: 'post-ad', component: PostAdComponent},
  {path: 'login', component: LoginComponent},
  {path: 'register', component: RegisterComponent},
  {path: 'about', component: AboutComponent},
  {path: 'terms', component: TermsComponent},
  {path: 'privacy', component: PrivacyComponent},
  {path: '404', component: NotfoundComponent},
  {path: '', component: HomeComponent},
  {path: '**', redirectTo: '/404'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled',
    scrollPositionRestoration: 'enabled'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
// tslint:disable-next-line:max-line-length
export const routingComponents = [AlladsComponent, PostAdComponent, LoginComponent, RegisterComponent, AboutComponent, TermsComponent, PrivacyComponent, NotfoundComponent, HomeComponent, AdDetailComponent]
