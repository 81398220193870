import { throwError as observableThrowError, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Iitem } from './item';
import { ISingleItem } from './item';
import { Ritem } from './item';
import { tap, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ItemsService {
  // tslint:disable-next-line:variable-name
  private _url_all_ads = 'https://api.tievs.com/vehicles?limit=60';
  // tslint:disable-next-line:variable-name
  private _url_ad = 'https://api.tievs.com/vehicle/';
  // tslint:disable-next-line:variable-name
  private _recommend_url = 'https://ml.tievs.com:2053/recommend/';


  constructor(private http: HttpClient) {}

  getItems(): Observable<Iitem[]> {
    return this.http.get<Iitem[]>(this._url_all_ads).pipe(
      tap((data) => JSON.stringify(data)),
      catchError(this.errorHandler)
    );
  }

  // tslint:disable-next-line:typedef
  errorHandler(error: HttpErrorResponse) {
    return observableThrowError(error.message || 'Server Error');
  }

  getItem(adID): Observable<Iitem> {
    return this.http.get<ISingleItem>(this._url_ad + adID).pipe(
      tap((data) => JSON.stringify(data)),
      catchError(this.errorHandler)
    );
  }

  getRecommend(adID): Observable<Ritem[]> {
    return this.http
      .get<Ritem[]>(this._recommend_url + adID + '?category=vehicles')
      .pipe(
        tap((data) => JSON.stringify(data)),
        catchError(this.errorHandler)
      );
  }

}
