import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';

interface Categories {
  value: string;
  viewValue: string;
}
@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.sass']
})
export class NavbarComponent implements OnInit {

  constructor(private cookieService: CookieService, private route: Router) { }
  ngOnInit(): void {
  }

  selectedValue: string;
  selectedCar: string;
  loggedin =  this.cookieService.check('user-id');
  logOut(){
    this.cookieService.delete('user-id');
    this.loggedin = false;
  }
  logIn(){
    if (!this.cookieService.check('user-id')){
      this.route.navigate(['/login']);
    }else {
      this.loggedin = false;
    }
  }

  categories: Categories[] = [
    {value: 'All Categories', viewValue: 'All Categories'},
    {value: 'Electronics', viewValue: 'Electronics'},
    {value: 'Vehicles', viewValue: 'Vehicles'},
    {value: 'Property', viewValue: 'Property'},
    {value: 'Home & Garden', viewValue: 'Home & Garden'},
    {value: 'Sports & Kids', viewValue: 'Sports & Kids'},
    {value: 'Business & Industry', viewValue: 'Business & Industry'},
    {value: 'Animals', viewValue: 'Animals'},
    {value: 'Fashion & Beauty', viewValue: 'Fashion & Beauty'},
    {value: 'Education', viewValue: 'Education'},
    {value: 'Agriculture', viewValue: 'Agriculture'},
    {value: 'Other', viewValue: 'Other'}
  ];

}

