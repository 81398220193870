import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Details } from '../details';

@Injectable({
  providedIn: 'root'
})
export class DetailsService {

  priceBaseURL: string = "https://ml.tievs.com:2096/";
  fraudBaseURL: string = "https://ml.tievs.com:2087/";
  private body: any;
  baseAdPostURL: string = "https://api.tievs.com/vehicle";
  private _emb_url = 'https://ml.tievs.com:2053/generate/';

  constructor(private http: HttpClient) { }

  predictPrice(detail:Details): Observable<any> {
    const headers = { 'content-type': 'application/json'}
    const body=JSON.stringify(detail);
    console.log(body)
    return this.http.post(this.priceBaseURL + 'predict-price', body,{'headers':headers})
  }

  detectFraudContent(detail: Details): Observable<any> {
    const headers = { 'content-type': 'application/json' }
    const text = detail.description;
    const body = {
        "description": text,
    };
    return this.http.post(this.fraudBaseURL + 'fraud-detection', body, { 'headers': headers })
  }

  createAd(detail: Details): Observable<any> {
    detail.odometer = detail.odometer.toString();
    detail.manufacturer = detail.manufacturer.toLowerCase();
    detail.user_id = 1;
    detail.state = "oh";
    const headers = { 'content-type': 'application/json'};
    const body = JSON.stringify(detail);
    console.log(body);
    return this.http.post(this.baseAdPostURL, body,{'headers': headers});
  }
  // tslint:disable-next-line:typedef
  generateEmb(adID){
    const headers = { 'content-type': 'text/plain'};
    return this.http.post(this._emb_url + adID + '?table=vehicles', this.body,{'headers': headers});
  }
}
