import {Component, Inject} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.sass']
})

export class DialogComponent {
  step = 0;
  selectedCategory: string;
  categories: string[] = ['Cars', 'Lorries & Trucks', 'Vans', 'MotorBikes'];
  selectedLocation: string;
  locations:string[] = [ 'Manhattan','Brooklyn','Queens','Bronx','Staten Island','New Jersey','Westchester','FairField co,CT']
  
  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }

  constructor(private formBuilder: FormBuilder,public dialog: MatDialog,public dialogRef: MatDialogRef<DialogComponent>  ) {}
  
  profileForm = this.formBuilder.group({
    selectedCategory: ['', [Validators.required]],
    selectedLocation: ['', [Validators.required]]
  });

  openDialog() {
    const dialogRef = this.dialog.open(DialogComponent);

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
  
  saveForm() {
    if (this.profileForm.valid) {
      localStorage.setItem('dialogData', JSON.stringify(this.profileForm.value))
      this.dialogRef.close();
    }
  }
}
