import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.sass']
})
export class LoginComponent implements OnInit {

  // constructor(private router: Router) { }
  // hide = true;
  // showSpinner = true;
  // username: string;

  // email = new FormControl('', [Validators.required, Validators.email]);
  // password = new FormControl(['',Validators.required]);

  // // tslint:disable-next-line:typedef
  // getErrorMessage() {
  //   if (this.email.hasError('required') || (this.password.hasError('required'))) {
  //     return 'You must enter a value';
  //   }

  //   return this.email.hasError('email') ? 'Not a valid email' : '';
  // }
  // ngOnInit(): void {
  // }

  // // login() : void {
  // //   if(this.username == 'admin' && this.password == 'admin'){
  // //    this.router.navigate(["/privacy"]);
  // //   }else {
  // //     alert("Invalid credentials");
  // //   }
  // // }

  hide = true;
  loginForm: FormGroup;
  emailRegx = /^(([^<>+()\[\]\\.,;:\s@"-#$%&=]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,3}))$/;

  constructor(
    private formBuilder: FormBuilder, public dialog: MatDialog, private route: Router, private cookieService: CookieService
  ) { }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      email: [null, [Validators.required, Validators.pattern(this.emailRegx)]],
      password: [null, Validators.required]
    });

    if (this.cookieService.check('user-id')){
      this.route.navigate(['/']);
    }
  }

  submit() {
    if (!this.loginForm.valid) {
      return;
    }
    if (this.loginForm.value.email === 'admin@tievs.com' && this.loginForm.value.password === '123'){
      console.log('Success');
      this.cookieService.set('user-id', '1');
      location.reload();
    }else {
      this.loginForm.reset();
      return;
    }
    console.log(this.cookieService.check('user-id'));
    console.log(this.loginForm.value.email);
  }
  register() {
    this.route.navigate(['/register']);
  }
}


