<div class="container" style="min-height: 100%; margin-top: 2%;">
  <div class="ad-details">
    <div class="title">
      <mat-toolbar>
        <h1>
          {{ ad.model + " " + ad.manufacturer | titlecase }}
        </h1>
      </mat-toolbar>
    </div>
    <div class="images" style="max-width: 760px">
      <mat-card
        ><img
          alt="vehicle placement"
          onerror="this.src='assets/image-placeholder.jpg';"
          src="{{ ad.image_url.String }}"
          style="width: 100%; height: auto"
        />
      </mat-card>
      <!-- Car description -->
      <div class="description" style="max-width: 760px">
        <mat-card>
          <mat-accordion>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <b>Description</b>
                </mat-panel-title>
                <mat-panel-description>
                  {{ ad.description.substring(0, 40) + "..." }}
                </mat-panel-description>
              </mat-expansion-panel-header>
              <p style="text-align: justify;">{{ ad.description }}</p>
            </mat-expansion-panel>
          </mat-accordion>
        </mat-card>
      </div>
    </div>
    <!-- Summary -->
    <div class="summary">
      <mat-card>
        <mat-list>
          <mat-list-item>
            <h2>Price <span style="color: darkgreen;">${{ ad.price }}.00</span></h2>
          </mat-list-item>

          <mat-list-item>
            <img class="icon-foo" alt="year" src="../../assets/year.png" />
            <strong>Year : </strong>
            <mat-panel-description style="margin-left: 10px">
              {{ ad.year }}
            </mat-panel-description>
          </mat-list-item>

          <mat-list-item>
            <img
              class="icon-foo"
              alt="odometer"
              src="../../assets/odometer.jpg"
            />
            <strong>Odometer : </strong>
            <mat-panel-description style="margin-left: 10px">
              {{ ad.odometer }}
            </mat-panel-description>
          </mat-list-item>

          <mat-list-item>
            <img class="icon-foo" alt="fuel" src="../../assets/fuel.jpg" />
            <strong>Fuel Type :</strong
            ><mat-panel-description style="margin-left: 10px"
              >{{ ad.fuel }}
            </mat-panel-description>
          </mat-list-item>

          <mat-list-item>
            <img
              class="icon-foo"
              alt="Transmission"
              src="../../assets/transmission.jpg"
            />
            <strong>Transmission :</strong>
            <mat-panel-description style="margin-left: 10px">
              {{ ad.transmission }}
            </mat-panel-description>
          </mat-list-item>
        </mat-list>

        <!-- Contact Details -->
        <div class="contact-details">
          <mat-accordion>
            <mat-panel-description>
              {{ ad.user_name.substring(0, 20) + "..." }}
            </mat-panel-description>

            <mat-list-item style="margin: 0px">
              <img
                alt="phone"
                src="../../assets/phone.png"
                style="width: 7%; height: 7%"
              />
              <h2>Call Seller</h2>
              {{ ad.user_name }}
            </mat-list-item>
            <mat-list-item style="margin: 0px">
              <div
                style="
                  background-color: rgb(248, 247, 247);
                  max-width: 40%;
                  max-height: 50%;
                  padding: 1%;
                  margin: 5px 5px;
                "
              >
                {{ ad.user_phone }}
              </div>
            </mat-list-item>
          </mat-accordion>
        </div>
      </mat-card>
    </div>
    <hr class="rounded">
    <!-- Similar Items -->
    <div class="recommend-title">
      <h4 style="font-size: 20px; font-weight: bold; margin-top: auto; margin-bottom: auto">Explore Similar Items</h4>
    </div>
    <div
      class="recommendations"
      style="display: flex; flex-wrap: wrap; justify-content: space-evenly"
    >
      <div
        *ngFor="let item of recommend"
        class="Items"
        style="max-width: 240px"
      >
        <!-- Card -->
        <a
          aria-label="Electronics"
          class="card card1"
          routerLink="/ad/{{ item.id }}"
        >
          <!-- Card image -->
          <div class="view overlay" style="margin: auto">
            <!--     <div id="electronics" ></div>-->
            <img
              alt="vehicle placement"
              onerror="this.src='assets/image-placeholder.jpg';"
              src="{{ item.image_url }}"
              style="width: 235px"
            />
            <div class="mask rgba-white-slight"></div>
          </div>
          <!-- Card content -->
          <div class="card-body">
            <!-- Title -->
            <h3 class="card-title" style="text-align: center">
              <b
                >{{ item.manufacturer | titlecase }}
                {{ item.model | titlecase }}</b
              >
            </h3>
            <!-- Text -->
            <p class="card-text">
              <b>{{ item.price | currency }}</b>
              <b style="margin-left: 42%">{{ item.year }}</b>
            </p>
          </div>
        </a>
      </div>
    </div>
  </div>
</div>
