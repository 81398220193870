<mat-horizontal-stepper [linear]="isLinear" #stepper style="min-height: 100%;">
  <!--CHOOSE OPTIONS-->
  <mat-step>
    <button class="btn btnRight" mat-fab style="background-color:#3872bb;" (click)="stepper.next()">
      <mat-icon>arrow_forward_ios</mat-icon>
    </button>
    <ng-template matStepLabel>Choose any option below</ng-template>
    <div>
      <h1 style="text-align: start; padding-top: 50px;">Welcome Olivia Smith ! Let's post an ad.</h1>
    </div>
    <div class="container">
      <div class="box">
        <img src="../../assets/postAd/car-sell.jpg" style="width: 100%;">
        <span>
          <h3>SELL SOMETHING</h3>
          <div>
            <div class="containerTwoR" style="margin-top: -18px;">
              <h2>
                <button mat-button (click)="openDialog(stepper)" style="background-color: rgb(78, 120, 235);">Sell an
                  item, property or service </button>
              </h2>
              <h2><button mat-button matStepperNext style="background-color: rgb(78, 120, 235);">Offer a property for
                  rent</button> </h2>
            </div>
          </div>
        </span>
      </div>
      <div class="box">
        <img src="../../assets/postAd/company-hiring.jpg" style="width: 100%;">
        <span>
          <h3>POST A JOB VACANCY</h3>
        </span>
        <div>
          <div class="containerTwoR" style="margin-top: -18px;">
            <h2><button mat-button matStepperNext style="background-color: rgb(196, 162, 48);">Post a job local</button>
            </h2>
            <h2><button mat-button matStepperNext style="background-color: rgb(196, 162, 48);">Post a job
                overseas</button></h2>
          </div>
        </div>
      </div>
      <div class="box">
        <img src="../../assets/postAd/buy.jpg" style="width: 100%;">
        <span>
          <h3>LOOK FOR SOMETHING</h3>
        </span>
        <div>
          <div class="containerTwoR" style="margin-top: -18px;">
            <h2><button mat-button matStepperNext style="background-color: rgb(196, 74, 92);">Look for something to
                buy</button></h2>
            <h2><button mat-button matStepperNext style="background-color: rgb(196, 74, 92);">Look for property to
                rent</button></h2>
          </div>
        </div>
      </div>
    </div>
    <div style="text-align-last:center;">
      <h4>Know Your Posting Allowance | <a target="_blank" href="#">Know Our Posting Rules</a></h4>
    </div>
  </mat-step>

  <!--ADDING ADVERTISEMENT DETAILS-->
  <mat-step [stepControl]="firstFormGroup" style="min-height: 100%;">
    <form [formGroup]="firstFormGroup" class="detail-form">
      <button class="btn btnLeft" mat-fab style="background-color:#3872bb;" matStepperPrevious>
        <mat-icon>arrow_back_ios</mat-icon>
      </button>
      <button class="btn btnRight" mat-fab style="background-color:#3872bb;" [disabled]="!detail.price" matStepperNext>
        <mat-icon>arrow_forward_ios</mat-icon>
      </button>
      <ng-template matStepLabel>Fill Necessary Details</ng-template>
      <!-- <mat-card> -->
      <mat-grid-list cols="3" rowHeight="4:5">
        <mat-grid-tile>
          <div class="controls-container">
            <mat-form-field appearance="fill">
              <mat-label>Year</mat-label>
              <input matInput type="number" step="10000" formControlName="year" placeholder="Ex: 2011"
                [(ngModel)]="detail.year">
            </mat-form-field>
            <mat-form-field appearance="fill">
              <mat-label>Manufacturer</mat-label>
              <input matInput type="text" formControlName="manufacturer" placeholder="Ex: ford"
                [(ngModel)]="detail.manufacturer">
            </mat-form-field>
            <mat-form-field appearance="fill">
              <mat-label>Model</mat-label>
              <input matInput type="text" formControlName="model" placeholder="Ex: f-150" [(ngModel)]="detail.model">
            </mat-form-field>
            <mat-form-field appearance="fill">
              <mat-label>Condition</mat-label>
              <mat-select formControlName="condition" [(ngModel)]="detail.condition">
                <mat-option *ngFor="let condition of Condition" [value]="condition" (change)="changeCondition($event)">
                  {{condition}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field appearance="fill">
              <mat-label>Cylinders</mat-label>
              <mat-select formControlName="cylinders" [(ngModel)]="detail.cylinders">
                <mat-option *ngFor="let cylinders of Cylinders" [value]="cylinders" (change)="changeCylinders($event)">
                  {{cylinders}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-label style="font-weight: bold;">Transmission</mat-label>
            <div class="radioBtns">
              <mat-radio-group formControlName="transmission" [(ngModel)]="detail.transmission">
                <mat-radio-button value="automatic">automatic</mat-radio-button>
                <mat-radio-button value="manual">manual</mat-radio-button>
                <mat-radio-button value="manual">other</mat-radio-button>
              </mat-radio-group>
            </div>
          </div>
        </mat-grid-tile>

        <mat-grid-tile>
          <div class="controls-container">
            <mat-form-field appearance="fill">
              <mat-label>Fuel</mat-label>
              <mat-select formControlName="fuel" [(ngModel)]="detail.fuel">
                <mat-option *ngFor="let fuel of Fuels" [value]="fuel" (change)="changeFuel($event)">
                  {{ fuel }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field appearance="fill">
              <mat-label>Title Status</mat-label>
              <mat-select formControlName="title_status" [(ngModel)]="detail.title_status">
                <mat-option *ngFor="let titleS of Title_Status" [value]="titleS" (change)="changeTitleStatus($event)">
                  {{ titleS }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field appearance="fill">
              <mat-label>Type</mat-label>
              <mat-select formControlName="type" [(ngModel)]="detail.type">
                <mat-option *ngFor="let type of Type" [value]="type" (change)="changeType($event)">
                  {{ type }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field appearance="fill">
              <mat-label>Odometer</mat-label>
              <input matInput type="number" step="10000" formControlName="odometer" placeholder="Ex: 101000"
                [(ngModel)]="detail.odometer">
            </mat-form-field>
            <mat-form-field appearance="fill">
              <mat-label>Drive</mat-label>
              <mat-select formControlName="drive" [(ngModel)]="detail.drive">
                <mat-option *ngFor="let drive of Drive" [value]="drive" (change)="changeDrive($event)">
                  {{ drive }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-label style="font-weight: bold;">Size</mat-label>
            <div class="radioBtns">
              <mat-radio-group formControlName="size" [(ngModel)]="detail.size">
                <mat-radio-button value="full-size">full-size</mat-radio-button>
                <mat-radio-button value="mid-size">mid-size</mat-radio-button>
                <mat-radio-button value="compact">compact</mat-radio-button>
                <mat-radio-button value="sub-compact">sub-compact</mat-radio-button>
              </mat-radio-group>
            </div>
          </div>
        </mat-grid-tile>
        <mat-grid-tile>
          <div class="controls-container">
            <mat-form-field appearance="fill">
              <mat-label>Paint Color</mat-label>
              <input matInput type="text" formControlName="paint_color" placeholder="Ex: black"
                [(ngModel)]="detail.paint_color">
            </mat-form-field>
            <mat-form-field appearance="fill">
              <mat-label>Price</mat-label>
              <input matInput class="example-right-align" type="number" step="10000" formControlName="price"
                placeholder="00" [(ngModel)]="detail.price">
              <span matPrefix>$&nbsp;</span>
              <span matSuffix>.00</span>
            </mat-form-field>
            <div *ngIf="showPrice" class="alert alert-success alert-duplicate"><div><strong style="font-size: x-large; margin-left: 17%;">{{ priceRange }}</strong></div></div>
            <button mat-raised-button matTooltip="Get the predicted price range" class="yellow_button"
              (click)="predictPrice()"
              [disabled]="!detail.year || !detail.manufacturer || !detail.model || !detail.condition || !detail.cylinders || !detail.fuel || !detail.title_status || !detail.type || !detail.odometer || !detail.drive || !detail.paint_color">Get
              Price Range</button>
            <img style="height:300px; padding: 0 0 0 0px; width: 100%;" src="../../assets/postAd/car.jpg" />
          </div>
        </mat-grid-tile>
      </mat-grid-list>
    </form> <br>
  </mat-step>
  <!--ADDING ADVERTISEMENT DETAILS END-->


  <!--DESCRIPTION AND IMAGE UPLOAD-->
  <mat-step [stepControl]="secondFormGroup" style="min-height: 100%;">
    <form [formGroup]="secondFormGroup" class="detail-form">
      <button class="btn btnLeft" mat-fab style="background-color:#3872bb;" matStepperPrevious>
        <mat-icon>arrow_back_ios</mat-icon>
      </button>
      <button class="btn btnRight" mat-fab style="background-color:#3872bb;" (click)="detectFraudContent(stepper)">
        <mat-icon>arrow_forward_ios</mat-icon>
      </button>
      <ng-template matStepLabel>Additional Info</ng-template>
      <mat-grid-list cols="3" rowHeight="">
        <mat-grid-tile>
          <img style="height: 100%; width: 98%;" src="../../assets/postAd/sell1.jpg" />
        </mat-grid-tile>
        <mat-grid-tile>
          <div class="controls-container">
            <mat-form-field appearance="fill">
              <mat-label>Title</mat-label>
              <input matInput type="text" formControlName="title" placeholder="About my car.."
                [(ngModel)]="detail.title">
            </mat-form-field>
            <mat-form-field class="example-full-width" appearance="fill">
              <mat-label>A Small Description</mat-label>
              <textarea matInput placeholder="Ex: 2018 BMW M3 CS Sedan Sedan" formControlName="description"
                style="height: 160px;" [(ngModel)]="detail.description"></textarea>
              <div *ngIf="showerror" class="alert alert-danger alert-duplicate">Fraud Warning !! Such deceptive ads are
                not allowed to be posted.</div>
            </mat-form-field>
          </div>
        </mat-grid-tile>

        <mat-grid-tile>
          <div class="controls-container">
            <strong style="text-transform: uppercase;">Contact Details</strong>
            <mat-divider></mat-divider><br>
            <mat-form-field class="example-full-width" appearance="fill">
              <mat-label>Contact Address</mat-label>
              <textarea matInput placeholder="Ex. Carvana is the safer way to buy" formControlName="address"  [(ngModel)]="detail.address"></textarea>
            </mat-form-field>
            <mat-form-field class="example-full-width" appearance="fill">
              <mat-label>Email Address</mat-label>
              <input matInput placeholder="Ex. XXXXX@123.com" formControlName="email"  [(ngModel)]="detail.email">
            </mat-form-field>
            <mat-form-field class="example-full-width" appearance="fill">
              <mat-label>Contact Number</mat-label>
              <input matInput type="number" mask="(000) 000-0000" placeholder="Ex. (000) 000-0000" value=""
                formControlName="contactNo" [(ngModel)]="detail.contactNumber">
            </mat-form-field>
          </div>
        </mat-grid-tile>
      </mat-grid-list>

      <mat-form-field class="example-full-width" appearance="fill">
        <mat-chip-list #chipList>
          <mat-chip style="display: none;">
          </mat-chip>
          <img *ngFor="let url of urls" [src]="url" alt="" width="300px" height="300px" formControlName="image"><br>
          <!-- Display files names -->
          <input type="file" #fileInput id="uploadFile" [formControl]="file"
            (change)="fileChange(index,$event); selectFiles($event)" name="uploadFile" multiple="multiple"
            accept="image/*" formControlName="image">
         <!--Upload Button-->
          <button  mat-raised-button mat-icon-button (click)="upload()" color="accent" ><mat-icon>cloud_upload</mat-icon></button>
           <!-- Browse Button -->
          <button style="background-color: #3872bb; color: white;" mat-raised-button>
            Browse Images
          </button>
        </mat-chip-list>

        <!-- Fetch selected filed on change -->
      </mat-form-field>

    </form> <br>
  </mat-step>
  <!--DESCRIPTION AND IMAGE UPLOAD END-->

  <!--FINAL STEP-->
  <mat-step style="min-height: 100%;">
    <button class="btnLast btnLastLeft" mat-fab style="background-color:#3872bb;" matStepperPrevious>
      <mat-icon>arrow_back_ios</mat-icon>
    </button>
    <ng-template matStepLabel>Confirm and Submit</ng-template>
    <div class="controls-container" style="width:71%; margin: 8% 17%">
      <strong style="margin-left: 2%; font-size: 15px;">Please tick the box below to confirm that you agree to comply with the terms and conditions
        of becoming an advertisinng customer in Tievs.</strong><br><br><br>
      <mat-checkbox [color]="primary" class="example-margin" #checkbox (change)="changeSubmitEvent(checkbox.checked)" style=""><div style="white-space: normal !important;">I accept the <a target="_blank" style="white-space: normal !important;" href="#">terms and
          conditions</a> of Tievs and I have read the <a target="_blank" style="white-space: normal !important;"  href="#">privacy policy</a> statements
        regarding posting advertisements thoroughly.</div></mat-checkbox><br><br>
      <div style="float: right;">
        <button mat-raised-button style="background-color:rgb(93, 171, 81); width: 100%;"
        [disabled]="toggleBool" (click)="submitAd()">Submit</button><br><br><br>
      </div>
      <button mat-raised-button (click)="stepper.reset()" style="background-color:rgb(193, 197, 187);">Cancel</button>
    </div>

  </mat-step>
</mat-horizontal-stepper>
