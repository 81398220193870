import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { DetailsService } from './services/details.service';
import {Details, FImage} from './details';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from '../dialog/dialog.component';
import { MatStepper } from '@angular/material/stepper';
import { COMMA, ENTER, S } from '@angular/cdk/keycodes';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-post-ad',
  templateUrl: './post-ad.component.html',
  styleUrls: ['./post-ad.component.sass'],
  providers: [{
    provide: STEPPER_GLOBAL_OPTIONS, useValue: {
      showError: true,
      displayDefaultIndicatorType: false,
      showPrice: false
    }
  }]
})
export class PostAdComponent implements OnInit {

  [x: string]: any;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  adID: number;
  priceRange: any;
  isLinear = false;
  toggleBool = true;
  action = 'Got it!';
  details: Details[];
  detail = new Details();
  fImage = new FImage();
  // Conditions
  Condition: any = ['good', 'excellent', 'like new', 'fair', 'new', 'salvage'];
  // Cylinders
  Cylinders: any = ['3 cylinders', '4 cylinders', '5 cylinders', '6 cylinders', '8 cylinders', '10 cylinders', '12 cylinders', 'other'];
  // Fuels
  Fuels: any = ['gas', 'diesel', 'electric', 'hybrid', 'other'];
  // Title Status
  Title_Status: any = ['clean', 'salvage', 'rebuilt', 'lien', 'missing', 'parts only'];
  //Type
  Type: any = ['sedan', 'SUV', 'pickup', 'truck', 'other', 'coupe', 'hatchback', 'wagon', 'van', 'convertible', 'mini-van', 'offroad', 'bus'];

  Drive: any = ['fwd', '4wd', 'rwd'];


  price: string;
  urls = [];
  file = new FormControl('');
  file_data: any = '';
  fileCount = 0;
  formData = new FormData();
  selectable = true;
  removable = true;
  addOnBlur = true;

  // @ViewChild('fileInput') fileInput: ElementRef;
  // fileAttr = '';
  readonly separatorKeysCodes = [ENTER, COMMA] as const;

  constructor(private route: Router, private _formBuilder: FormBuilder, private detailService: DetailsService, public snackBar: MatSnackBar, public dialog: MatDialog, private httpClient: HttpClient) {
  }

  ngOnInit(): void {
    this.firstFormGroup = this._formBuilder.group({
      year: ['', Validators.required],
      manufacturer: ['', Validators.required],
      model: ['', Validators.required],
      condition: ['', Validators.required],
      cylinders: ['', Validators.required],
      fuel: ['', Validators.required],
      odometer: ['', Validators.required],
      title_status: ['', Validators.required],
      transmission: ['', Validators.required],
      drive: ['', Validators.required],
      size: ['', Validators.required],
      type: ['', Validators.required],
      paint_color: ['', Validators.required],
      price: ['']
    });

    this.secondFormGroup = this._formBuilder.group({
      description: ['', Validators.required],
      title: ['', Validators.required],
      image: [''],
      address: ['', Validators.required],
      contactNo: ['', Validators.required],
      email: ['', Validators.required]
    });
  }

  changeCondition(e) {
    this.condition.setValue(e.target.value, {
      onlySelf: true
    });
  }

  changeFuel(e) {
    this.fuel.setValue(e.target.value, {
      onlySelf: true
    });
  }

  changeTitleStatus(e) {
    this.title_status.setValue(e.target.value, {
      onlySelf: true
    });
  }

  changeCylinders(e) {
    this.cylinders.setValue(e.target.value, {
      onlySelf: true
    });
  }

  changeType(e) {
    this.type.setValue(e.target.value, {
      onlySelf: true
    });
  }

  changeDrive(e) {
    this.drive.setValue(e.target.value, {
      onlySelf: true
    });
  }

  predictPrice() {
    this.detailService.predictPrice(this.detail)
      .subscribe(data => {
        var predictedPrice = parseFloat(data.price);
        var firstRange1 = predictedPrice - 5000.00;
        var firstRange = (Math.round(firstRange1 * 100) / 100).toFixed(2);
        var secondRange1 = predictedPrice + 5000.00;
      
        this.priceRange = ' $ ' + firstRange + ' - $ ' + secondRange1;
        this.showPrice = true;
        console.log(this.priceRange);
        console.log(data);
      });
  }

  detectFraudContent(stepper: MatStepper) {
    this.detailService.detectFraudContent(this.detail)
      .subscribe(data => {
        if (data.prediction === 'spam') {
          this.showerror = true;
        } else {
          stepper.next();
          this.showerror = false;
        }
      });
  }

  selectFiles(event) {
    const fileList: FileList = event.target.files;
    if (event.target.files) {
      for (var i = 0; i < fileList.length; i++) {
        var reader = new FileReader();

        reader.readAsDataURL(event.target.files[i]);
        reader.onload = (event: any) => {
          this.urls.push(event.target.result);
        };
      }
    }
  }

  fileChange(index, event) {
    const fileList: FileList = event.target.files;
    console.log(fileList.length);
    //check whether file is selected or not
    if (fileList.length > 0) {
      for (var i = 0; i < fileList.length; i++) {
        const file = fileList[i];
        // Check fraud
        const formImage = new FormData();
        formImage.append('image', file, file.name);

        this.httpClient.post('https://catsy.org/check-vehicle/', formImage)
          .subscribe((data: FImage) => {
             if (data.is_vehicle === false) {
               alert('Please Upload a valid photo');
             }
          });
        // get file information such as name, size and type
        console.log('finfo', file.name, file.size, file.type);
        let name = 'image_url_' + i;
        console.log(name);
        //max file size is 4 mb
        if ((file.size / 1048576) <= 4) {
          this.formData.append(name, file, file.name);
          this.file_data = this.formData;

        } else {
          //this.snackBar.open('File size exceeds 4 MB. Please choose less than 4 MB','',{duration: 2000});
        }
      }
    }
  }

  upload() {
    let imageid = 0;
    this.formData.forEach((value, key) => {
      //console.log(value, key);
      let fileData = new FormData();
      // @ts-ignore
      if ('name' in value) {
        fileData.append('file', value, value.name);
        if (imageid === 0) {
          this.detail.image_url = 'https://ml.tievs.com/images/vehicles/' + value.name;
        }else if (imageid === 1) {
          this.detail.image_url1 = 'https://ml.tievs.com/images/vehicles/' + value.name;
        }else if (imageid === 2) {
          this.detail.image_url2 = 'https://ml.tievs.com/images/vehicles/' + value.name;
        }else if (imageid === 3) {
          this.detail.image_url3 = 'https://ml.tievs.com/images/vehicles/' + value.name;
        }else if (imageid === 4) {
          this.detail.image_url4 = 'https://ml.tievs.com/images/vehicles/' + value.name;
        }else if (imageid === 5) {
          this.detail.image_url5 = 'https://ml.tievs.com/images/vehicles/' + value.name;
        }
        imageid++;
      }
      this.httpClient.post('http://upload.tievs.com/images/file-upload.php', fileData)
        .subscribe(response => {

        });

    });

  }

  changeSubmitEvent(e) {
    if (e == true) {
      this.toggleBool = false;
    }
    else {
      this.toggleBool = true;
    }

  }
  submitAd() {
    this.detailService.createAd(this.detail)
      .subscribe(data => {
      console.log(data);
      this.adID = data.id;
      this.dialog.open(SuccessSubmission);
      this.route.navigate(['']);
      this.detailService.generateEmb(this.adID).subscribe(response => {
        console.log(response);
      });
    });
  }

  // }
  openDialog(stepper: MatStepper) {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '700px',
    });
    dialogRef.afterClosed().subscribe(result => {
      stepper.next();
    });
  }


  // add(event: MatChipInputEvent): void {
  //   const value = (event.value || '').trim();

  //   // Add our fruit
  //   if (this.fileAttr) {
  //     this.fruits.push({name: value});
  //   }

  //   // Clear the input value
  //   event.input.value = '';
  // }

  // remove(fruit: Fruit): void {
  //   const index = this.fruits.indexOf(fruit);

  //   if (index >= 0) {
  //     this.fruits.splice(index, 1);
  //   }
  // }
}

@Component({
  selector: 'success-submission',
  templateUrl: 'success-submission.html',
})
export class SuccessSubmission {
}



  // uploadFileEvt(imgFile: any) {
  //   if (imgFile.target.files && imgFile.target.files[0]) {
  //     this.fileAttr = '';
  //     Array.from(imgFile.target.files).forEach((file: File) => {
  //       this.fileAttr += file.name + ' / ';
  //     });

  //     // HTML5 FileReader API
  //     let reader = new FileReader();
  //     reader.onload = (e: any) => {
  //       let image = new Image();
  //       image.src = e.target.result;
  //       image.onload = rs => {
  //         let imgBase64Path = e.target.result;
  //       };
  //     };
  //     reader.readAsDataURL(imgFile.target.files[0]);

  //     // Reset if duplicate image uploaded again
  //     this.fileInput.nativeElement.value = "";
  //   } else {
  //     this.fileAttr = 'Upload Images';
  //   }
