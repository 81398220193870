export class Details{
    [x: string]: any;
    price: number;
    year: number;
    manufacturer: string;
    model: string;
    condition: string;
    cylinders: string;
    fuel: string;
    odometer: string;
    transmission: string;
    type: string;
    description: string;
    state: string;
    region: string;
    size: string;
    drive: string;
    title_status: string;
    paint_color: string;
    title: string;
    latitude: number;
    longitude: number;
    image_url: string;
    image_url1: string;
    image_url2: string;
    image_url3: string;
    image_url4: string;
    image_url5: string;
    slog: string;
    user_id: number;
    address: string;
    email: string;
    contactNumber: number;
}

export class FImage{
  // tslint:disable-next-line:variable-name
  is_vehicle: boolean;
  detail: string;
}
