import { Component, OnInit } from '@angular/core';
import { ItemsService} from '../items.service';
import {MatPaginatorModule} from '@angular/material/paginator';

@Component({
  selector: 'app-allads',
  templateUrl: './allads.component.html',
  styleUrls: ['./allads.component.sass']
})
export class AlladsComponent implements OnInit {
  p: number = 1;
  collection: any[] ;

  public items = [];
  public errorMsg;
  // tslint:disable-next-line:variable-name
  constructor(private _itemsService: ItemsService) { }

  ngOnInit(): void {
   this._itemsService.getItems()
     .subscribe(data => this.items = data,
       error => this.errorMsg = error);
  }

}
