import { Injectable } from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class PostAdService {

  constructor() { }

  form: FormGroup = new FormGroup({
    $key: new FormControl(null),
    year: new FormControl(''),
    manufacturer: new FormControl(''),
    model: new FormControl(''),
    condition: new FormControl(0),
    cylinders: new FormControl(0),
    fuel: new FormControl(0),
    odometer: new FormControl(''),
    title_status: new FormControl(''),
    transmission: new FormControl(''),
    drive: new FormControl(''),
    size: new FormControl(''),
    type1: new FormControl(''),
    paint_color: new FormControl('')
  });
}
