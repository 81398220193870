<!-- <div class="example-container" style="min-height: 100%;">
  <mat-form-field appearance="fill">
    <mat-label>Enter your email</mat-label>
    <input matInput placeholder="pat@example.com" [formControl]="email" required>
    <mat-error *ngIf="email.invalid">{{getErrorMessage()}}</mat-error>
  </mat-form-field>
  <br>
  <mat-form-field appearance="fill">
    <mat-label>Enter your password</mat-label>
    <input matInput [type]="hide ? 'password' : 'text'">
    <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
      <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
    </button>
  </mat-form-field><br>
  <button mat-flat-button color="primary" class="blue_button" onsubmit="login()" >Login</button>
</div> -->
<div class="example-container" style="min-height: 50%; margin-bottom: 2%; border: 10px;" >
  <div class="form-container">
    <form [formGroup]="loginForm" (submit)="submit()" style=" width: 34%;
    margin-left: 33%;
    margin-top: 6%;
    margin-bottom: 10%; border-style: groove; padding: 2%;">
    <strong style="font-size: x-large; color: #3872bb; font-style: italic;">Log in to tievs</strong><br><br><br>
      <mat-form-field class="form-field" appearance="outline" style="font-size: 18px;">
        <mat-label> E-mail
        </mat-label>
        <input matInput formControlName="email" required value="">
        <mat-error *ngIf="loginForm.controls.email.touched && loginForm.controls.email.invalid">
          <span *ngIf="loginForm.controls.email.errors.required">This field is mandatory.</span>
          <span *ngIf="loginForm.controls.email.errors.pattern">This field is invalid.</span>
        </mat-error>
      </mat-form-field>
      <mat-form-field class="form-field" appearance="outline" style="font-size: 18px;">
        <mat-label> Password
        </mat-label>
        <input matInput formControlName="password" value="" [type]="hide ? 'password' : 'text'"required>
        <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
          <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
        </button>
        <mat-error *ngIf="loginForm.controls.password.touched && loginForm.controls.password.invalid">
          <span *ngIf="loginForm.controls.password.errors.required">This field is mandatory.</span>
        </mat-error>
      </mat-form-field>
      <button mat-raised-button color="primary" type="submit" style="width: 95%; font-size: 120%;">Login</button><br><br>
      <a href="">Forgot Password ?</a>
    </form>
  </div>
  <mat-list style="width: 30%;margin-left: 35%;margin-top: -9%;">
    <mat-divider></mat-divider><br>
    <strong style="font-size: larger;">Don't have an account yet ?</strong><br><br>
    <button mat-raised-button color="accent" type="submit" style="width: 95%; font-size: 120%;" (click)="register()">Sign Up</button><br><br>
  </mat-list>
</div>