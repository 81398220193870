<div class="fixed-top d-flex flex-column flex-md-row align-items-center px-md-4 border-bottom shadow-sm"
     id="navBar" style="margin-top:-1%;">
  <div class="container">
    <nav class="wrapper">
      <a routerLink="/" id="imagelogo">
        <img alt="logo"
             class="logoImage"
             src="./assets/logo-sm.webp"
        style="margin-top: 12%;"/></a>
      <div class="allAdsBtn">
        <a routerLink="/ads"><button class="blue_button" color="primary" id="allAdsBtn" mat-raised-button style="margin-top: 16%;">All Ads</button></a></div>
      <div class="middleBox">
        <div class="searchBox input-group">
          <input aria-label="Text input with dropdown button" class="form-control" placeholder="What are you looking for?"
                 type="text" style="height: 52px; margin-top: 3%;"/>
          <div class="input-group-prepend" id="searchOpt" style="margin-top: 3%;height: 41px;">
              <mat-form-field appearance="fill">
                <mat-label>Category</mat-label>
                <mat-select [(ngModel)]="selectedValue" name="categories">
                  <mat-option *ngFor="let category of categories" [value]="category.value">
                    {{category.viewValue}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
          </div>
          <button aria-label="Enter Search" class="btn" id="searchBtn" mat-raised-button color="primary" type="button" style="height: 52px;margin-top: 3%;">
            <svg class="svg-icon" viewBox="0 0 20 20">
              <path fill="white" d="M19.129,18.164l-4.518-4.52c1.152-1.373,1.852-3.143,1.852-5.077c0-4.361-3.535-7.896-7.896-7.896
								c-4.361,0-7.896,3.535-7.896,7.896s3.535,7.896,7.896,7.896c1.934,0,3.705-0.698,5.078-1.853l4.52,4.519
								c0.266,0.268,0.699,0.268,0.965,0C19.396,18.863,19.396,18.431,19.129,18.164z M8.567,15.028c-3.568,0-6.461-2.893-6.461-6.461
								s2.893-6.461,6.461-6.461c3.568,0,6.46,2.893,6.46,6.461S12.135,15.028,8.567,15.028z"></path>
            </svg></button>
        </div>
      </div>
      <div id="loginPost">
        <button *ngIf=loggedin id="logout" (click)="logOut()"  mat-stroked-button style="margin-top: 11%;">Log out</button>
        <button *ngIf=!loggedin routerLink="/login" (click)="logIn()" id="loginBtn" mat-stroked-button style="margin-top: 11%;">Login</button>
        <button routerLink="/post-ad" class="blue_button" color="primary" id="postBtn" style="margin-top: 11%;" mat-raised-button>POST YOUR AD</button>
      </div>
    </nav>
  </div>
</div>
