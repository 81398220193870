
<div class="Header" style="height: 400px; background-color: #f5f5f5;">
  <div class="container headContainer">
    <div class="Block-1">
    <h1 style="color: #1b1b1b; font-weight: 500">
      Welcome to tievs.com - the best marketplace in USA
    </h1>
    <h2 style="color: #171616; font-weight: 500; text-align: justify;">
      Buy and sell everything from used cars to mobile phones and computers, or search for property, jobs and more in America!
    </h2>
    </div>
    <div class="Block-2">
      <object class="alignCenter" type="image/svg+xml" data="./assets/home/US_Map_sm.svg">US Map</object>
    </div>
  </div>
</div>
<div class="middle-body" style="padding-top: 1em; background-color: #f5f5f5">
<div class="container" >
  <h2 style="text-align: center; padding-top: 0.25em; padding-bottom: 0.25em; color: white; background-color: #1a5ea6;">Browse our top categories:</h2>
  <div class="categories">
  <!-- Card -->
  <div class="card card1" routerLink="/ads/usa/electronics" aria-label="Electronics">

    <!-- Card image -->
    <div class="view overlay" style="margin: auto">
      <div id="electronics" ></div>
        <div class="mask rgba-white-slight"></div>
    </div>

    <!-- Card content -->
    <div class="card-body">

      <!-- Title -->
      <h3 class="card-title">Electronics</h3>
      <!-- Text -->
      <p class="card-text" style="text-align: justify;">Find great deals for used electronics in Sri Lanka including mobile phones, computers, laptops, TVs, home theatres and much more.</p>

    </div>

  </div>
  <!-- Card -->
    <!-- Card -->
    <div class="card card2" routerLink="/ads" aria-label="Vehicles">

      <!-- Card image -->
      <div class="view overlay" style="margin: auto">
        <div id="vehicles" ></div>
          <div class="mask rgba-white-slight"></div>
      </div>

      <!-- Card content -->
      <div class="card-body">

        <!-- Title -->
        <h3 class="card-title" (click)="GoToVehicles()">Vehicles</h3>
        <!-- Text -->
        <p class="card-text" style="text-align: justify;">Buy and sell used cars, motorbikes and other vehicles in US. Choose from top brands including Toyota, Nissan, Honda and Suzuki.</p>

      </div>

    </div>
    <!-- Card -->
    <!-- Card -->
    <div class="card card3" routerLink="/ads/usa/property" aria-label="Property" >

      <!-- Card image -->
      <div class="view overlay" style="margin: auto">
        <div id="property" ></div>
          <div class="mask rgba-white-slight"></div>
      </div>

      <!-- Card content -->
      <div class="card-body">

        <!-- Title -->
        <h3 class="card-title">Property</h3>
        <!-- Text -->
        <p class="card-text" style="text-align: justify;">View listings for property in US. Find the cheapest rates for apartments, commercial and residential properties, land, and plots.</p>

      </div>

    </div>
    <!-- Card -->
    <!-- Card -->
    <div class="card card4" routerLink="/ads/usa/animals" aria-label="Animals" >

      <!-- Card image -->
      <div class="view overlay" style="margin: auto">
        <div id="animals" ></div>
          <div class="mask rgba-white-slight"></div>
      </div>

      <!-- Card content -->
      <div class="card-body">

        <!-- Title -->
        <h3 class="card-title">Animals</h3>
        <!-- Text -->
        <p class="card-text" style="text-align: justify;">Search from the widest variety of pets in America. Select from dogs, puppies, cats, kittens, birds and other domesticated animals.</p>

      </div>

    </div>
    <!-- Card -->
    <!-- Card -->
    <div class="card card5" routerLink="/ads/usa/home-garden" aria-label="Home and Garden" >

      <!-- Card image -->
      <div class="view overlay" style="margin: auto">
        <div id="home-garden" ></div>
          <div class="mask rgba-white-slight"></div>
      </div>

      <!-- Card content -->
      <div class="card-body">

        <!-- Title -->
        <h3 class="card-title">Home & Garden</h3>
        <!-- Text -->
        <p class="card-text" style="text-align: justify;">Buy and sell new and used home appliances including furniture, kitchen items, garden products and other household items in USA.</p>

      </div>

    </div>
    <!-- Card -->
    <!-- Card -->
    <div class="card card6" routerLink="/ads/usa/business-industry" aria-label="Business & Industry" >

      <!-- Card image -->
      <div class="view overlay" style="margin: auto">
        <div id="business-industry" ></div>
          <div class="mask rgba-white-slight"></div>
      </div>

      <!-- Card content -->
      <div class="card-body">

        <!-- Title -->
        <h3 class="card-title">Business & Industry</h3>
        <!-- Text -->
        <p class="card-text" style="text-align: justify;">A range of business services and industrial companies offering products and services to both other businesses and consumers alike.</p>

      </div>

    </div>
    <!-- Card -->
  </div>
</div>
</div>
