<div class="content" style="min-height: 100%">
  <div class="Filter">
  </div>
  <div class="container" style="display: flex; flex-wrap: wrap; justify-content: space-evenly">
  <div class="Items" *ngFor="let item of items | paginate:
 { itemsPerPage: 12, currentPage: p }">
    <!-- Card -->
    <a class="card card1" routerLink="/ad/{{item.id}}" aria-label="Electronics" >
      <!-- Card image -->
      <div class="view overlay" style="margin: auto">
<!--     <div id="electronics" ></div>-->
        <img src="{{item.image_url.String}}" onerror="this.src='assets/image-placeholder.jpg';"  alt="vehicle placement" style="height: 225px; width: 300px">
        <div class="mask rgba-white-slight"></div>
      </div>
      <!-- Card content -->
      <div class="card-body">
        <!-- Title -->
        <h3 class="card-title" style="text-align: center"><b>{{item.manufacturer | titlecase}} {{item.model | titlecase}}</b></h3>
        <!-- Text -->
        <p class="card-text"> <b>{{item.price | currency}}</b> <b style="margin-left: 52%; ">{{item.year}}</b></p>
      </div>
    </a>
  </div>
    <pagination-controls
      (pageChange)="p = $event"
      class="float-right">
    </pagination-controls>
  </div>
</div>
