<form [formGroup]="profileForm" #ngForm="ngForm" class="form-container">
    <mat-accordion class="dialog-headers-align">
        <!--CHOOSE CATEGORY-->
        <mat-expansion-panel [expanded]="step === 0" (opened)="setStep(0)" >
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <h1 mat-dialog-title>Choose Category</h1>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <ng-template matExpansionPanelContent>
                <h2 mat-dialog-title>Vehicles</h2>
                <mat-radio-group [ngClass]="{'invalid': ngForm.submitted && profileForm.get('selectedCategory').invalid}"
                formControlName="selectedCategory" aria-labelledby="dialog-radio-group-label" class="dialog-radio-group"
                    [(ngModel)]="selectedCategory">
                    <mat-radio-button class="dialog-radio-button" *ngFor="let category of categories"
                        [value]="category">
                        {{category}}
                    </mat-radio-button>
                </mat-radio-group>
            </ng-template>
            <mat-action-row>
                <button mat-button color="primary" (click)="nextStep()">Next</button>
            </mat-action-row>
        </mat-expansion-panel>

        <!--CHOOSE lOCATION-->
        <mat-expansion-panel [expanded]="step === 1" (opened)="setStep(1)" >
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <h1 mat-dialog-title>Choose Location</h1>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <ng-template matExpansionPanelContent>
                <h2 mat-dialog-title>New York</h2>
                <mat-radio-group  [ngClass]="{'invalid': ngForm.submitted && profileForm.get('selectedLocation').invalid}"
                formControlName="selectedLocation"  aria-labelledby="dialog-radio-group-label" class="dialog-radio-group"
                    [(ngModel)]="selectedLocation">
                    <mat-radio-button class="dialog-radio-button" *ngFor="let location of locations"
                        [value]="location">
                        {{location}}
                    </mat-radio-button>
                </mat-radio-group>
            </ng-template>
            <mat-action-row>
                <button mat-button color="warn" (click)="prevStep()">Previous</button>
                <button mat-button color="primary" (click)="saveForm()">Confirm</button>
            </mat-action-row>
        </mat-expansion-panel>
    </mat-accordion>
</form>