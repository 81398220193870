<div class="example-container" style="min-height: 50%; margin-bottom: 2%">
    <div class="form-container">
        <form [formGroup]="registerForm" (submit)="submit()" style=" width: 34%;
      margin-left: 33%;
      margin-top: 5%;
      margin-bottom: 10%; border-style: groove; padding: 2%;">
         <strong style="font-size: x-large; color: #3872bb;font-style: italic;">Sign up for tievs</strong><br><br><br>
            <mat-form-field class="form-field" appearance="outline" style="font-size: 14px;">
                <mat-label> Name
                </mat-label>
                <input matInput formControlName="email" required value="">
                <mat-error *ngIf="registerForm.controls.email.touched && registerForm.controls.email.invalid">
                    <span *ngIf="registerForm.controls.email.errors.required">This field is mandatory.</span>
                    <span *ngIf="registerForm.controls.email.errors.pattern">This field is invalid.</span>
                </mat-error>
            </mat-form-field>
            <mat-form-field class="form-field" appearance="outline" style="font-size: 14px;">
                <mat-label> E-mail
                </mat-label>
                <input matInput formControlName="email" required value="">
                <mat-error *ngIf="registerForm.controls.email.touched && registerForm.controls.email.invalid">
                    <span *ngIf="registerForm.controls.email.errors.required">This field is mandatory.</span>
                    <span *ngIf="registerForm.controls.email.errors.pattern">This field is invalid.</span>
                </mat-error>
            </mat-form-field>
            <mat-form-field class="form-field" appearance="outline" style="font-size: 14px;">
                <mat-label> Password
                </mat-label>
                <input matInput formControlName="password" value="" [type]="hide ? 'password' : 'text'" required>
                <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
                    [attr.aria-pressed]="hide">
                    <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                </button>
                <mat-error *ngIf="registerForm.controls.password.touched && registerForm.controls.password.invalid">
                    <span *ngIf="registerForm.controls.password.errors.required">This field is mandatory.</span>
                </mat-error>
            </mat-form-field>
            <mat-form-field class="form-field" appearance="outline" style="font-size: 14px;">
                <mat-label> Re-enter Password
                </mat-label>
                <input matInput formControlName="password" value="" [type]="hide ? 'password' : 'text'" required>
                <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
                    [attr.aria-pressed]="hide">
                    <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                </button>
                <mat-error *ngIf="registerForm.controls.password.touched && registerForm.controls.password.invalid">
                    <span *ngIf="registerForm.controls.password.errors.required">This field is mandatory.</span>
                </mat-error>
            </mat-form-field>
            <button mat-raised-button color="accent" type="submit" style="width: 95%; font-size: 120%;">Sign
                Up</button><br><br>
            <p>By signing up for an account you agree to our <a href="">Terms and Conditions</a></p>
        </form>
    </div>
    <mat-list style="width: 30%;margin-left: 35%;margin-top: -9%;">
        <mat-divider></mat-divider><br>
        <strong style="font-size: larger;">Already have an account ?</strong><br><br>
        <button mat-raised-button color="primary" type="submit" style="width: 95%; font-size: 120%;"
            (click)="login()">Login</button><br><br>
    </mat-list>
</div>